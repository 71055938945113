.table-cell {
  padding: 10px;
  font-size: 14px; /* Adjust the font size as needed */
  /* Add any other styles you want for your table cells here */
}
td{
  font-size: 15px;
}

.roster-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.roster-table th, .roster-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
}

.roster-table th {
    background-color: #f4f4f4;
}

.roster-table .present {
    background-color: red;
    color: white;
}
.roster-table .leave {
    background-color: red;
    color: white;
}
.fixed-column {
    position: sticky;
    left: 0; 
    background: white; 
    z-index: 10; 
}

.followup-check-row {
    background-color: red;
    color: white; 
  }
  .pac-container {
    z-index: 9999 !important;
}
  

.work-row{
	background-color: white !important;

}
.leave-row{
	background-color: #df2a2a !important ;
}
.weekend-row{
	background-color: #df2a2a !important ;
}
.present-row{
	background-color: #2cb302;
}
.half-row{
	background-color:#62d3d9 ;
}
.wfh-taking-row{
	background-color: gray;

}
.leave-taking-row{
	background-color: #df2a2a ;
}
.roster-taking-row{
	background-color: #df2a2a;

}
.absent-row{
	background-color: #df2a2a;

}



.smalls-cards-popup-s {
  position: absolute;
  left: 70%; 
  margin-top: -20px; 
  z-index: 999;
}

/*.cards-popups {
  width: 600px;
  height: 350px; 
  background-color:'green'
}*/

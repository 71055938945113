table.attendencetable {
    border-collapse: collapse;
    width: 100%;
   }
  
  table.attendencetable th,table.attendencetable td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
  }
  
  table.attendencetable th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
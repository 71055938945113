.pagination-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.custom-pagination {
  display: flex;
  justify-content: flex-end;
}

.page-link-style {
  border: 1px solid #ddd; 
  margin: 0 5px; 
  padding: 5px; 
  border-radius: 5px; 
  cursor: pointer;
}

.break-link-style,
.prev-link-style,
.next-link-style {
  margin: 0 5px; 
  padding: 5px; 
  cursor: pointer;
}
.active {
  color:'red'

}

.filter-group {
  margin-bottom: 15px;
}

.filter-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.filter-input {
  position: relative;
}

.filter-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.filter-select:focus {
  outline: none;
  border-color: #6cb2eb;
  box-shadow: 0 0 0 0.1rem rgba(108, 178, 235, 0.5);
}

.filter-select option {
  font-size: 16px;
}

.filter-select::-ms-expand {
  display: none;
}

.filter-select-arrow {
  position: absolute;
  top: calc(50% - 5px);
  right: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #888;
  pointer-events: none;
}

.filter-select:focus + .filter-select-arrow {
  border-top-color: #6cb2eb;
}
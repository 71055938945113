
  .leave-details {
    border: 1px solid #ccc;
    padding: 15px;
    margin-top: 20px;
    background-color:blue;
    color:white;
    width: 190px;
  }

  .leave-details p {
    margin: 0;
    font-size: 16px;
  }
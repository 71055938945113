.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.page-link-style, .prev-link-style, .next-link-style, .break-link-style {
  padding: 10px 15px;
  margin: 0 5px;
  border: 1px solid #ddd;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
}

.page-link-style:hover, .prev-link-style:hover, .next-link-style:hover, .break-link-style:hover {
  background-color: #f0f0f0;
}

.active .page-link-style {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #3D3D45;
}

::-webkit-scrollbar-track {
  background: #EBEDF0;
} 
.max-content{
  width: max-content
}
a.btn.btn-primary.mb-2 {
  width: max-content;
}
button.btn.btn-primary.small.tabss {
    background-color: transparent;
    color: black;
    border: none;
}

button.btn.btn-primary.small.tabss:focus {
   box-shadow: none;
   border: none;
}
button.btn.btn-primary.small.tabss.active {
    border-bottom: 2px solid black;
}
@keyframes blink {
    0% {
      background-color: #ff0000; 
      color: #ffffff; 
    }
    50% {
      background-color: #ffffff; 
      color: #ff0000; 
    }
    100% {
      background-color: #ff0000; 
      color: #ffffff; 
    }
  }
  
  .punch-in-button {
    animation: blink 1s infinite; 
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    border: 1px solid red;
  }
  
  .punch-in-button.blurred {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @keyframes glow {
    0% {
      background-color: green;
      color: #ffffff; 
    }
    50% {
      background-color: #ffffff; 
      color: #ff0000; 
    }
    100% {
      background-color: green; 
      color: #ffffff; 
    }
  }
  
  .animate-glow {
    animation: glow 1.5s infinite alternate;
    border: none;
    padding: 10px 20px;
    font-size:22px;
    cursor: pointer;
    border-radius:9px;
    outline: none;
    border: 4px solid red;
  }
  
  
  
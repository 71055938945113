.small-cards-popup-s {
  position: absolute;
  top: 60px;
  z-index: 999;
  right: 0;
}

.cards-popup {
  width: 400px;
  height: auto;
  color: #ffffff;
  font-size: 12px;
}
.small-cards-mypopup{
  width: 400px;
  position: absolute;
  background-color: #17a2b8;
  right: 0;
  top:60px;
}



.description-popup {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  white-space: pre-wrap; /* Ensures line breaks and spacing are respected */
}
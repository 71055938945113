.dashboard-category-main-card {
  text-align: center;
  color: #ffffff;
  padding: 6px;
  background-color: #1770b8;
  font-size: 17px;
  border-radius: 5px;
  align-items: center;
  transition-duration: .3s;
  border: 1px solid #1770b8;
}

.dashboard-category-main-card:hover {
  color: #1770b8;
  background-color: white;
  transition-duration: .3s;
}